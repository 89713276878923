module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portfolio","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58a82c48818ffe7e9a71e8be9835ac38"},
    },{
      plugin: require('../../../../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"goodpolyamory","accessToken":"MC5YLVRIUFJBQUFDRUFxRldH.Je-_ve-_vWjvv70iNS0LRO-_vUDvv71Rd--_ve-_ve-_ve-_vSrvv71Nc--_ve-_vXHvv73vv73vv73vv71v77-9","fetchLinks":[],"schemas":{"portfoliolink":{"Main":{"overlay_text":{"type":"StructuredText","config":{"single":"rtl","label":"Overlay Text"}},"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image"}},"link":{"type":"Link","config":{"allowTargetBlank":true,"label":"link"}}}}},"lang":"*","prismicToolbar":false,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"typePathsFilenamePrefix":"prismic-typepaths---gatsby-source-prismic-test-site"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
